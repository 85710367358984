import { useState, useEffect } from 'react'
import { useStore } from '@nanostores/react'
import { isSearchOpen } from '@stores/searchStore'
import { isMobileMenuOpen } from '@stores/menuStore'
import { simulateClick } from '@utils/utils.js'

import SearchIcon from '@components/Search/SearchIcon/SearchIcon.jsx'

const NavSearch = () => {
    const [isHovered, setIsHovered] = useState(false)

    const $isSearchOpen = useStore(isSearchOpen || false)
    const $isMobileMenuOpen = useStore(isMobileMenuOpen || false)

    useEffect(() => {
        if ($isSearchOpen) {
            const main = document.querySelector('main')
            main.classList.remove('menu-overlay')
            const menuClose = document.querySelector('.menu-close')
            if ($isMobileMenuOpen) {
                simulateClick(menuClose)
            }
        }
    }, [$isSearchOpen])

    const toggleSearch = () => {
        isSearchOpen.set(!$isSearchOpen)

        setTimeout(() => {
            const searchInput = document.getElementById('boost-search-input')

            searchInput.focus()
        }, 400)
    }

    return (
        <div className="nav-search relative mr-8 lg:mr-4 flex items-center">
            <input
                placeholder="Search"
                className={`search-input items-center lg:w-56 xl:w-64 2xl:w-72 h-8 bg-slate/50 rounded-sm border-1 border px-2 hover:cursor-pointer hover:border-blue-400 hover:border-2 hidden lg:flex ${
                    isHovered ? 'border-blue-400' : 'border-stone-300'
                }`}
                onClick={toggleSearch}
            />

            <div
                className="search-icon ml-auto mr-1 absolute cursor-pointer mr-4 lg:left-[88%] xl:left-[90%] 2xl:left-[90%] lg:mr-0"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={toggleSearch}
            >
                <SearchIcon width="w-[18px]" />
            </div>
        </div>
    )
}

export default NavSearch
